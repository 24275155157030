import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { CustomerBasicDetailsService } from './service/customer-basic-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomerBasicDetails, addressDTO } from './model/customer-basic-details.model';
import { ToastrService } from 'ngx-toastr';
import { CustomModalService } from '../custom-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import _ from 'underscore';
import { TokenStorageService } from '../../auth/token-storage-service';
import { LoginService } from '../../pages/login/login.service';
import { PubSubService } from '../../services/pubsub/pubsub.service';
import { ResePwdServiceService } from '../../pages/reset-password/rese-pwd-service.service';
import { malicliousInputValidator } from '../../pages/cases/add-case/add-case.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-basic-details',
  templateUrl: './customer-basic-details.component.html',
  styleUrls: ['./customer-basic-details.component.scss']
})
export class CustomerBasicDetailsComponent implements OnInit {
  baseUrl: string;
  isSessionexpire:boolean;
  latitude: any;
  longitude: any;
  username: any;
  paymentpincode: any;
  presentpincode: any;
  relativeSourceList=[];


  constructor(private ngbModalService: NgbModal,private resetPasswordService: ResePwdServiceService, private pubsub: PubSubService, private activate: ActivatedRoute, private loginService: LoginService, private tokenservice: TokenStorageService, private router: Router, private entityService: CustomerBasicDetailsService, private tostr: ToastrService, private customModalService: CustomModalService) { }
  public data: CustomerBasicDetails
  public presentAddress: addressDTO
  public paymentAddress: addressDTO
  public submitted: boolean = false
  public showGSTInput: boolean = false
  public enablePayment: boolean = true;
  public invalidMobileAsPerApi: boolean = false;
  public invalidMobileAsPerApiMsg: string = '';
  public pincode: string
  public invalidUsernameAsPerApiMsg: string = '';
  public invalidUsernameAsPerApi: boolean = false;
  public invalidEmailAsPerApi: boolean = false;
  public invalidEmailAsPerApiMsg: string = ''
  public invalidInputMessage = "Input must not contain the characters <, >, (\", \"), (', or ')"
  public mobileOtp: any = null;
  public email: any
  public md5Id: any;
  public mobile: any;
  showMap = false;
  markerPosition: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  public presentPincodeId: any
  public paymentPincodeId: any
  public onChange: any = () => { };
  public onTouched: any = () => { };
  public titleList = []
  public GSTEnableList = [
    {
      text: 'Yes'
    },
    {
      text: 'No'
    },
  ]


  @Output() tempPartnerId = new EventEmitter();
  @Output() stateId = new EventEmitter();
  leadSourceList=[];
  rcustname: any;
  // @ViewChild(OtherDetailsComponent, { static: false }) OtherDetailsComponent: OtherDetailsComponent;
  // @ViewChild(PlanServiceComponent, { static: false }) planServiceComponent: PlanServiceComponent;
  paymentRecievedValue: any;
  paymentRecievedFlage: boolean = false;
  hidePaymentAddressSection: boolean = true;
  paymentRequiredFlage: boolean = false;
  // AclClassConstants = AclClassConstants;
  // AclConstants = AclConstants;
  subscriptions: Subscription[] = [];
  collectorList: any[] = [];
  subscription: Subscription;
  partnerId: number;
  aadhaar_OTP: number;
  Gst: any;
  req_message = "This field is required.";
  selected_payment_mode_value: any;
  enableMactal: boolean = true;
  enableVlan: boolean = false;
  enableAutoGenPass: boolean = false;
  public CustomerList: string;
  autoField: any = ['username','name']
  custId: number;
  custname: string;
  invalidCafNoAsPerApi: boolean = false;
  invalidCafNoAsPerApiMsg: string = '';
  mactalLabel: string = '';
  userName: any;
  partnerFlag: any;
  propertyTypeList: any
  client_id: string
  default_pincode_value: string;
  imagePath: string;
  backendImageUrl: string;
  isVerifybtn: boolean = true;
  aadharverified: boolean = false;
  mobileverified: boolean = false;
  parentpin: any;
  TypeOfProperty: any;
  Area: any;
  @ViewChild('select', { static: false }) select: NgSelectComponent;
  // @ViewChild(AddMactalComponent, { static: false })
  // private addMactalComponent: AddMactalComponent;
  franchiesList: any;
  buildingHouseName: any;
  isAadhaarbtn: boolean = true;
  isImage: boolean = false;
  block_House_No: any;
  longituteObj:any;
  latituteObj:any;
  property_Type: any;
  dynamicrequiredArray: any;
  firstname: any;
  middlename: any;
  lastname: any;
  caf_no: any;
  customerSourceList: []
  customer_source: { isMandatory: boolean; displayname: boolean }
  usernameOfReferredByFriendOrRelative: { isMandatory: boolean; displayname: boolean }
  alt_mobile: any;
  sales_representative_id: any
  searchPincodeLink: string = '';
  addressMapArray: any;
  addressMapArray2: any;
  addressMapArray3: any;
  buildingList: [];
  typeOfPropertyList: any;
  parentId: number;
  commonYN: any
  public addressMapArray6: any
  public addressMapArray5: any
  public addressMapArray4: any
  public areaAllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];

  public pincodeAllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];

  public countryList: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateList: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public cityList: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];
  public paymentModeList: any[] = [
    {
      payment_mode_text: '',
      payment_mode_value: '',
    },
  ];

  public salesRepresentativeList: any[] = [
    {
      fullname: '',
      id: '',
    },
  ];
  public countryListPayment: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public stateListPayment: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public cityListPayment: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];
  public country2List: any[] = [
    {
      country_name: '',
      country_id: '',
    },
  ];
  public state2List: any[] = [
    {
      state_name: '',
      state_id: '',
    },
  ];
  public city2List: any[] = [
    {
      city_name: '',
      city_id: '',
    },
  ];
  public area1AllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public pincode1AllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];
  public area2AllList: any[] = [
    {
      name: '',
      id: '',
    },
  ];
  public pincode2AllList: any[] = [
    {
      pincode: '',
      pincodeid: '',
    },
  ];
  block_House_NoPayment: any;
  property_TypePayment: any;
  addressMapArrayPayment: any;
  addressMapArray2Payment: any;
  addressMapArray3Payment: any;
  buildingListPayment: [];
  buildingList1: [];
  buildingList2: [];
  typeOfPropertyListPayment: any;
  typeOfPropertyList2: any;
  subscriptionsPayment: Subscription[] = [];
  dynamicrequiredArrayPayment: any[] = [];
  default_pincode1_value: string = '';
  default_pincode2_value: string = '';
  parentpinPayment: any
  ngOnInit() {
    this.md5Id = this.activate.snapshot.paramMap.get('id');
    const baseUrl = `${location.protocol}//${location.host}`;
    const currentUrl = this.router.url;
    const fullUrl = `${baseUrl}${'/#'}${currentUrl}`;
    if(fullUrl){
      const urlFromBackend = fullUrl
      const hashIndex = urlFromBackend.indexOf('#')
      const nextSlashIndex = urlFromBackend.indexOf('/', hashIndex + 2)
      this.baseUrl = urlFromBackend.substring(0, nextSlashIndex + 1);
      let obj={
         "url":this.baseUrl,
          "leadMD5Token":this.md5Id
      }
      this.resetPasswordService.sessionexpire(obj).subscribe((res)=>{
        if(res.responseCode==200){
          this.isSessionexpire=res.data.sessionExpired
          if(this.isSessionexpire){
            this.router.navigate(['/session-expire'])
            return
            }
        }
      })
    }
    if (this.md5Id && this.md5Id !== null) {
      if (this.resetPasswordService.isVerifyCustomer) {
        let obj = {
          id: this.md5Id
        }
        this.entityService.getbasicdetails1(obj).subscribe((res) => {
          this.setData(res)
        })
      }
      else {
        this.router.navigate(['customer-basic-details-verify-customer', this.md5Id])
        // this.tostr.erro
      }
    }
    else {
      if (!this.resetPasswordService.isVerifyCustomer) {
        this.router.navigate(['customer-basic-details-verify-customer'])
        // this.tostr.erro
      }
    }
    this.getLeadSourceList();
    this.getRelativerSourceList();
    this.getSubscriberCustomer();
    console.log(this.formGroup.controls)
    this.loginService.getLevelOfAddress()
    // this.getRequiredAllField();
    // this.getRequiredAllField2();
    this.getTitle()
    this.data = new CustomerBasicDetails()
    if(this.router.url.includes('customer-basic-details')){
      this.data.leadOrigin='External'
    }
    this.data.sameAsPresent = true
    this.paymentAddress = new addressDTO()
    this.presentAddress = new addressDTO()
    this.getAddressMapping();
    this.getAddressMapping2();
    this.getPincode();
    this.getcommonList();
  }
  getPincode() {
    this.searchPincodeLink = 'lead/operation/searchNew?id=' + this.parentpin + '&s=';
  }
  getTitle() {
    this.entityService.getTitleList().subscribe((res: any) => {
      this.titleList = res.dataList
    })
  }
  getcommonList() {
    this.entityService.getCommonListPropertyType().subscribe((res) => {
      this.propertyTypeList = res.dataList;
    })
  }
  setData(response) {
    this.data = response.data
    this.username=this.data.relativeUsername
    if (this.data.sameAsPresent === true) {
      this.hidePaymentAddressSection = true
      this.enablePayment = true
    }
    else {
      this.hidePaymentAddressSection = false
      this.enablePayment = false
    }
    if (this.data.gstNo) {
      this.showGSTInput = true
      this.formGroup.get('isGSTEnable').setValue('Yes')
    }
    console.log(this.md5Id)
    if (response.data.staticIp === true)
      this.data.staticIp = true
    else {
      this.data.staticIp = false
    }
    if (response.data.telephoneLine === true)
      this.data.telephoneLine = true
    else {
      this.data.telephoneLine = false
    }
    this.presentAddress = response.data.addressList[0]
    this.paymentAddress = response.data.addressList[1]
    this.presentAddress.typeOfProperty = response.data.addressList[0].typeOfProperty
    this.paymentAddress.typeOfProperty = response.data.addressList[1].typeOfProperty
    this.presentAddress.buildingId = response.data.addressList[0].buildingId
    this.paymentAddress.buildingId = response.data.addressList[1].buildingId
    this.default_pincode_value = response.data.addressList[0].pincode
    this.default_pincode2_value = response.data.addressList[1].pincode
    this.presentPincodeId = response.data.addressList[0].pincodeId
    this.paymentPincodeId = response.data.addressList[1].pincodeId
    this.formGroup.get('area').setValue(response.data.addressList[0].areaId)
    this.paymentAddressFormGroup.get('area2').setValue(response.data.addressList[1].areaId)
    this.presentAddress.typeOfProperty = response.data.addressList[0].typeOfProperty
    this.paymentAddress.typeOfProperty = response.data.addressList[1].typeOfProperty
    if (this.presentAddress.typeOfProperty) {
      this.getPropertyType()
    }
    if (this.paymentAddress.typeOfProperty) {
      this.getPropertyType2()
    }
    this.formGroup.get('buildingId').setValue(response.data.addressList[0].buildingId)
    this.paymentAddressFormGroup.get('buildingId2').setValue(response.data.addressList[0].buildingId)
    if(this.data.leadSource=='referredByFriendOrRelative'){
      if(this.data.relativeUsername){
        this.formGroup.get('relativeUsername').patchValue(this.data.relativeUsername)
      }
      
    }
  }
  formGroup = new FormGroup({
    latitude: new FormControl(null, [Validators.required, latitudeValidatorlead()]),
    plan: new FormControl(null, [Validators.required,malicliousInputValidator()]),
    longitude: new FormControl(null, [Validators.required, longitudeValidatorlead()]),
    title: new FormControl(null, [Validators.required]),
    leadSource:new FormControl(null,[Validators.required]),
    relativeUsername:new FormControl(null),
    aadhar: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{12}$')]),
    // userName: new FormControl('', [Validators.required, this.cannotContainSpace, Validators.pattern('^[a-zA-Z0-9_]+$')]),
    mobileNo: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{10}$')]),
    telephone: new FormControl('', Validators.pattern('^[0-9]{10}$')),
    email: new FormControl("", [Validators.required, Validators.email]),
    address: new FormControl('', [Validators.required,malicliousInputValidator()]),
    typeOfProperty: new FormControl(null),
    landmark: new FormControl(null,[malicliousInputValidator()]),
    area: new FormControl(null),
    Country: new FormControl(null,),
    State: new FormControl(null),
    relativeSource:new FormControl(null),
    City: new FormControl(null,),
    District: new FormControl(null),
    Taluka: new FormControl(null),
    Constituency: new FormControl(null),
    block: new FormControl(null,[malicliousInputValidator()]),
    building: new FormControl(null),
    propertyType: new FormControl(null),
    collectorid: new FormControl(null),
    middleName: new FormControl(null),
    lastName: new FormControl(null, [Validators.required,malicliousInputValidator()]),
    firstName: new FormControl(null, [Validators.required,malicliousInputValidator()]),
    isGSTEnable: new FormControl(null, Validators.required),
    gstNo: new FormControl(null),
    panNo: new FormControl('', [Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]),
    alternateMobileNo: new FormControl('',[Validators.pattern('^[0-9]{10}$')]),
    countryId: new FormControl(null, [Validators.required]),
    pincodeId: new FormControl(null, [Validators.required]),
    stateId: new FormControl(null, [Validators.required]),
    cityId: new FormControl(null, [Validators.required]),
    districtId: new FormControl(null),
    talukaId: new FormControl(null),
    constituencyId: new FormControl(null),
    areaId: new FormControl(null),
    buildingId: new FormControl(null),
  })
  paymentAddressFormGroup = new FormGroup({
    address2: new FormControl(null),
    landmark2: new FormControl(null,[malicliousInputValidator()]),
    area2: new FormControl(null),
    pincode2: new FormControl(''),
    Country2: new FormControl(null,),
    State2: new FormControl(null,),
    City2: new FormControl(null,),
    Taluka2: new FormControl(null),
    District2: new FormControl(null),
    Constituency2: new FormControl(null),
    countryId2: new FormControl(null),
    stateId2: new FormControl(null),
    areaId2: new FormControl(null),
    cityId2: new FormControl(null),
    talukaId2: new FormControl(null),
    districtId2: new FormControl(null),
    constituencyId2: new FormControl(null),
    pincodeId2: new FormControl(null, [Validators.required]),
    buildingId2: new FormControl(null),
    typeOfProperty2: new FormControl(null),
    block2: new FormControl(null,[malicliousInputValidator()])
  })
  get paymentF() {
    return this.paymentAddressFormGroup.controls
  }
  get f() {
    return this.formGroup.controls
  }
  onReset() {
    this.submitted = false
    this.formGroup.reset()
    this.data.staticIp = false
    this.data.telephoneLine = false
    this.default_pincode_value = null
    this.default_pincode2_value = null
    this.formGroup.get('isGSTEnable').setValue('No')
    this.paymentAddressFormGroup.reset()
  }
  formSubmit() {
    this.data.addressList = new Array<addressDTO>()
    this.submitted = true
    if (this.formGroup.invalid) {
      const invalidFields = [];
      const controls = this.formGroup.controls;

      // Iterate through the controls and check for validity
      Object.keys(controls).forEach(key => {
        const control = controls[key];

        if (control.invalid) {
          invalidFields.push(key);
        }
      });

      console.log('Invalid Fields:', invalidFields);
      console.log(this.formGroup.controls)
      this.tostr.error('Please Fill all Required Details', 'Error')
      return
    }
    this.getPaymentAddress()
    this.getPresentAddress()
    console.log(this.data)
    console.log(this.formGroup)
    this.entityService.saveBasicDetails(this.data).subscribe((res: any) => {
      if (res.responseCode === 200) {
        this.tostr.success('Data Saved Successfully!!', 'Success')
        this.router.navigate(['thank-you-page'])
        this.resetPasswordService.isVerifyCustomer = false
      }
    }, (error) => {
      this.onResponseError(error.message);
    })
  }
  public onResponseError(message: string) {
    this.tostr.error(message.replace('\n', '<br/>'), 'Error', { enableHtml: true });
  }
  getPresentAddress() {
    this.presentAddress.countryId = this.formGroup.get('countryId').value,
      this.presentAddress.stateId = this.formGroup.get('stateId').value,
      this.presentAddress.cityId = this.formGroup.get('cityId').value,
      this.presentAddress.districtId = this.formGroup.get('districtId').value,
      this.presentAddress.constituencyId = this.formGroup.get('constituencyId').value,
      this.presentAddress.talukaId = this.formGroup.get('talukaId').value,
      this.presentAddress.buildingId = this.formGroup.get('building').value,
      this.presentAddress.areaId = this.formGroup.get('area').value,
      this.presentAddress.pincodeId = this.formGroup.get('pincodeId').value
    this.presentAddress.addressType = 'present'
    this.data.addressList.push(this.presentAddress)

  }
  getPaymentAddress() {
    if (!this.hidePaymentAddressSection) {
      this.paymentAddress.countryId = this.paymentAddressFormGroup.get('countryId2').value,
        this.paymentAddress.stateId = this.paymentAddressFormGroup.get('stateId2').value,
        this.paymentAddress.cityId = this.paymentAddressFormGroup.get('cityId2').value,
        this.paymentAddress.districtId = this.paymentAddressFormGroup.get('districtId2').value,
        this.paymentAddress.constituencyId = this.paymentAddressFormGroup.get('constituencyId2').value,
        this.paymentAddress.talukaId = this.paymentAddressFormGroup.get('talukaId2').value,
        this.paymentAddress.buildingId = this.paymentAddressFormGroup.get('buildingId2').value,
        this.paymentAddress.areaId = this.paymentAddressFormGroup.get('area2').value,
        this.paymentAddress.pincodeId = this.paymentAddressFormGroup.get('pincode2').value,
        this.paymentAddress.address1 = this.paymentAddressFormGroup.get('address2').value,
        this.paymentAddress.landmark = this.paymentAddressFormGroup.get('landmark2').value,
        this.paymentAddress.blockNo = this.paymentAddressFormGroup.get('block2').value,
        this.paymentAddress.typeOfProperty = this.paymentAddressFormGroup.get('typeOfProperty2').value,
        this.paymentAddress.addressType = 'payment'
      this.data.addressList.push(this.paymentAddress)
    }
    else if (this.hidePaymentAddressSection) {
      this.paymentAddress.countryId = this.formGroup.get('countryId').value,
        this.paymentAddress.stateId = this.formGroup.get('stateId').value,
        this.paymentAddress.cityId = this.formGroup.get('cityId').value,
        this.paymentAddress.districtId = this.formGroup.get('districtId').value,
        this.paymentAddress.constituencyId = this.formGroup.get('constituencyId').value,
        this.paymentAddress.talukaId = this.formGroup.get('talukaId').value,
        this.paymentAddress.buildingId = this.formGroup.get('building').value,
        this.paymentAddress.areaId = this.formGroup.get('area').value,
        this.paymentAddress.pincodeId = this.formGroup.get('pincodeId').value,
        this.paymentAddress.address1 = this.formGroup.get('address').value,
        this.paymentAddress.landmark = this.formGroup.get('landmark').value,
        this.paymentAddress.blockNo = this.formGroup.get('block').value,
        this.paymentAddress.typeOfProperty = this.formGroup.get('typeOfProperty').value,
        this.paymentAddress.addressType = 'payment'
      this.data.addressList.push(this.paymentAddress)
    }
  }
  onEmailInput(email) {
    if (email === '') {
      this.invalidEmailAsPerApi = false
    }
  }
  onMobileInput(mobile) {
    if (mobile === '') {
      this.invalidMobileAsPerApi = false
    }
  }
  onUserNameInput(userName) {
    if (userName === '') {
      this.invalidUsernameAsPerApi = false
    }
  }
  getRequiredAllField() {
    this.mobile = null;
    this.entityService.getRequiredAllField().subscribe(result => {
      console.log("res===>", result)

      if (result.responseCode === 200) {

        this.dynamicrequiredArray = result.data;
        this.firstname = this.dynamicrequiredArray.find((e => e.fieldname === 'firstname'));
        this.middlename = this.dynamicrequiredArray.find((e => e.fieldname === 'middlename'));
        this.lastname = this.dynamicrequiredArray.find((e => e.fieldname === 'lastname'));

        this.customer_source = this.dynamicrequiredArray.find((e => e.fieldname === 'customer_source'));

        this.usernameOfReferredByFriendOrRelative = this.dynamicrequiredArray.find(e => e.fieldname === 'usernameOfFriendOrRelative')

        this.mobile = this.dynamicrequiredArray.find((e => e.fieldname === 'mobileNo'));
        this.Gst = this.dynamicrequiredArray.find((e => e.fieldname === 'gst'));
        this.caf_no = this.dynamicrequiredArray.find((e => e.fieldname === 'caf_no'));
        this.email = this.dynamicrequiredArray.find((e => e.fieldname === 'email'));
        this.alt_mobile = this.dynamicrequiredArray.find((e => e.fieldname === 'alt_mobile'));
        this.sales_representative_id = this.dynamicrequiredArray.find(e => e.fieldname == 'salesrepid');
        this.block_House_No = this.dynamicrequiredArray.find(e => e.fieldname === 'blockHouseNo')
        this.longituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'longitude')
        this.latituteObj = this.dynamicrequiredArray.find(e => e.fieldname === 'latitude')
        this.Area = this.dynamicrequiredArray.find(e => e.fieldname === 'Area')
        this.property_Type = this.dynamicrequiredArray.find(e => e.fieldname === 'propertyType')
        this.buildingHouseName = this.dynamicrequiredArray.find(e => e.fieldname === 'BuildingHouseName')
        this.TypeOfProperty = this.dynamicrequiredArray.find(e => e.fieldname === 'typeOfProperty')


        if ((this.firstname !== null && this.firstname !== undefined) && this.firstname.isMandatory === true) {
          this.formGroup.get('firstname').setValidators(Validators.required)
          this.formGroup.get('firstname').updateValueAndValidity();
        }
        else {
          this.formGroup.get('firstname').clearValidators()
          this.formGroup.get('firstname').updateValueAndValidity();
        }

        if ((this.middlename !== null && this.middlename !== undefined) && this.middlename.isMandatory === true) {
          this.formGroup.get('middlename').setValidators(Validators.required)
          this.formGroup.get('middlename').updateValueAndValidity();
        }
        else {
          this.formGroup.get('middlename').clearValidators()
          this.formGroup.get('middlename').updateValueAndValidity();
        }

        if ((this.lastname !== null && this.lastname !== undefined) && this.lastname.isMandatory === true) {
          this.formGroup.get('lastname').setValidators(Validators.required)
          this.formGroup.get('lastname').updateValueAndValidity();
        }
        else {
          this.formGroup.get('lastname').clearValidators()
          this.formGroup.get('lastname').updateValueAndValidity();
        }
        if ((this.mobile !== null && this.mobile !== undefined) && this.mobile.isMandatory === true) {
          this.formGroup.get('mobileNo').setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')])
          this.formGroup.get('mobileNo').updateValueAndValidity();
        }
        else {
          this.formGroup.get('mobileNo').clearValidators();
          this.formGroup.get('mobileNo').setValidators(Validators.pattern('^[0-9]{10}$'))
          this.formGroup.get('mobileNo').updateValueAndValidity();
        }

        if ((this.caf_no !== null && this.caf_no !== undefined) && this.caf_no.isMandatory === true) {
          this.formGroup.get('caf_no').setValidators(Validators.required)
          this.formGroup.get('caf_no').updateValueAndValidity();
        }
        else {
          this.formGroup.get('caf_no').clearValidators()
          this.formGroup.get('caf_no').updateValueAndValidity();
        }

        if ((this.email !== null && this.email !== undefined) && this.email.isMandatory === true) {
          this.formGroup.get('email').setValidators([Validators.required, Validators.email]);
          this.formGroup.get('email').updateValueAndValidity();
        }
        else {
          this.formGroup.get('email').clearValidators();
          this.formGroup.get('email').setValidators(Validators.email);
          this.formGroup.get('email').updateValueAndValidity();
        }
        if ((this.customer_source !== null && this.customer_source !== undefined) && this.customer_source.isMandatory === true) {
          this.formGroup.get('customerSource').setValidators([Validators.required]);
          this.formGroup.get('customerSource').updateValueAndValidity();
        }
        else {
          this.formGroup.get('customerSource').clearValidators();
          this.formGroup.get('customerSource').setValidators(null);
          this.formGroup.get('customerSource').updateValueAndValidity();
        }


        if ((this.alt_mobile !== null && this.alt_mobile !== undefined) && this.alt_mobile.isMandatory === true) {
          this.formGroup.get('alternate_mobileNo').setValidators([Validators.required, Validators.pattern('^[0-9]{10}$')]);
          this.formGroup.get('alternate_mobileNo').updateValueAndValidity();
        }
        else {
          this.formGroup.get('alternate_mobileNo').clearValidators();
          this.formGroup.get('alternate_mobileNo').setValidators(Validators.pattern('^[0-9]{10}$'))
          this.formGroup.get('alternate_mobileNo').updateValueAndValidity();
        }
        if (this.sales_representative_id && this.sales_representative_id.isMandatory === true) {
          this.formGroup.get('sales_representative_id').setValidators([Validators.required]);
          this.formGroup.get('sales_representative_id').updateValueAndValidity();
        }
        else {
          this.formGroup.get('sales_representative_id').clearValidators();
          this.formGroup.get('sales_representative_id').updateValueAndValidity();
        }


        if (this.block_House_No && this.block_House_No.isMandatory === true) {
          this.formGroup.get('block').setValidators([Validators.required]);
          this.formGroup.get('block').updateValueAndValidity();
        } else {
          this.formGroup.get('block').clearValidators();
          this.formGroup.get('block').updateValueAndValidity();
        }

        if (this.longituteObj && this.longituteObj.isMandatory === true) {
          this.formGroup.get('longitude').setValidators([Validators.required]);
          this.formGroup.get('longitude').updateValueAndValidity();
        } else {
          this.formGroup.get('longitude').clearValidators();
          this.formGroup.get('longitude').updateValueAndValidity();
        }

        if (this.latituteObj && this.latituteObj.isMandatory === true) {
          this.formGroup.get('latitude').setValidators([Validators.required]);
          this.formGroup.get('latitude').updateValueAndValidity();
        } else {
          this.formGroup.get('latitude').clearValidators();
          this.formGroup.get('latitude').updateValueAndValidity();
        }

        if (this.property_Type && this.property_Type.isMandatory === true) {
          this.formGroup.get('propertyType').setValidators([Validators.required]);
          this.formGroup.get('propertyType').updateValueAndValidity();
        } else {
          this.formGroup.get('propertyType').clearValidators();
          this.formGroup.get('propertyType').updateValueAndValidity();
        }

        if (this.TypeOfProperty && this.TypeOfProperty.isMandatory === true) {
          this.formGroup.get('typeOfProperty').setValidators([Validators.required]);
          this.formGroup.get('typeOfProperty').updateValueAndValidity();
        } else {
          this.formGroup.get('typeOfProperty').clearValidators();
          this.formGroup.get('typeOfProperty').updateValueAndValidity();
        }

        if (this.buildingHouseName && this.buildingHouseName.isMandatory === true) {
          this.formGroup.get('building').setValidators([Validators.required]);
          this.formGroup.get('building').updateValueAndValidity();
        } else {
          this.formGroup.get('building').clearValidators();
          this.formGroup.get('building').updateValueAndValidity();
        }

        if ((this.Area !== null && this.Area !== undefined) && this.Area.isMandatory === true) {
          this.formGroup.get('area').setValidators(Validators.required)
          this.formGroup.get('area').updateValueAndValidity();
        }
        else {
          this.formGroup.get('area').clearValidators()
          this.formGroup.get('area').updateValueAndValidity();
        }


      }
    })
  }
  getRequiredAllField2() {
    this.entityService.getRequiredAllField().subscribe(result => {

      if (result.responseCode === 200) {
        this.dynamicrequiredArray = result.data;
        this.block_House_No = this.dynamicrequiredArray.find(e => e.fieldname === 'blockHouseNo');
        this.property_Type = this.dynamicrequiredArray.find(e => e.fieldname === 'PropertyType');


        if ((this.block_House_No !== null && this.block_House_No !== undefined) && this.block_House_No.isMandatory === true) {
          this.paymentAddressFormGroup.get('block1').setValidators(Validators.required)
          this.paymentAddressFormGroup.get('block1').updateValueAndValidity();
          this.paymentAddressFormGroup.get('block2').setValidators(Validators.required)
          this.paymentAddressFormGroup.get('block2').updateValueAndValidity();
        }
        else {
          this.paymentAddressFormGroup.get('block1').reset()
          this.paymentAddressFormGroup.get('block1').clearValidators()
          this.paymentAddressFormGroup.get('block1').updateValueAndValidity();

          this.paymentAddressFormGroup.get('block2').reset()
          this.paymentAddressFormGroup.get('block2').clearValidators()
          this.paymentAddressFormGroup.get('block2').updateValueAndValidity();
        }


        // if ((this.property_Type !== null && this.property_Type !== undefined) && this.property_Type.isMandatory === true) {
        //     this.paymentAddressFormGroup.get('service_area').setValidators(Validators.required);
        //     this.paymentAddressFormGroup.get('service_area').updateValueAndValidity();
        //      }
        // else {
        //     //this.paymentAddressFormGroup.get('service_area').reset()
        //     // this.paymentAddressFormGroup.get('service_area').clearValidators()
        //     // this.paymentAddressFormGroup.get('service_area').updateValueAndValidity();
        //  }
      }
    })
  }
  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (control && control.value && (control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }
    return null;
  }
  spaceCheck(value) {
    return value.indexOf(' ') >= 0;
  }
  onTouchedandChange() {
    let value = this.formGroup.value;
    this.onChange(value);
    this.onTouched(value);
  }
  checkUniqueCustomerByUsername(el) {
    let value = el.target.value;
    if (value && value.length) {
      if (!this.spaceCheck(value)) {
        this.invalidUsernameAsPerApi = false;
        this.entityService.checkUniqueCustomerByUsername(value).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidUsernameAsPerApi = true;
            this.invalidUsernameAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidUsernameAsPerApi = false;
            this.invalidUsernameAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      }
    } else {
      this.invalidUsernameAsPerApi = false;
      this.invalidUsernameAsPerApiMsg = '';
    }
  }
  onGSTEnable(event) {
    if (event.text === "Yes") {
      this.showGSTInput = true
      this.formGroup.get('gstNo').reset()
      this.formGroup.get('gstNo').setValidators([Validators.required, Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9]{1}$')])
      this.formGroup.updateValueAndValidity()
    }
    else {
      this.showGSTInput = false
      this.formGroup.get('gstNo').clearValidators()
      this.formGroup.updateValueAndValidity()
      this.formGroup.get('gstNo').reset()
    }
  }
  setFocusOnHtmlElements(elementName, timeoutTime) {
    let el = document.getElementById(elementName);
    setTimeout(() => {
      if (el)
        el.focus();
    }, timeoutTime);
  }
  numberOnlyInput(event) { }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkUniqueCustomerByMobile(el) {
    console.log(this.formGroup.controls)
    if (this.formGroup.get('mobileNo').value !== null &&
      this.formGroup.get('mobileNo').value !== undefined) {
      ////console.log("Mobile Uni"+this.formGroup.get('mobileNo').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidMobileAsPerApi = false;
        if (value.length !== 10) {
        }
        this.entityService.checkUniqueCustomerByMobile(value, null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidMobileAsPerApi = true;
            this.invalidMobileAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidMobileAsPerApi = false;
            this.invalidMobileAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.invalidMobileAsPerApi = false;
        this.invalidMobileAsPerApiMsg = '';
      }

    }
    else {
      //3 Line New code
      this.invalidMobileAsPerApi = false;
      this.invalidMobileAsPerApiMsg = '';
      this.onTouchedandChange();
    }

  }
  clearMessage() {
    if (this.formGroup.controls['mobileNo'].invalid &&
      (this.formGroup.controls['mobileNo'].dirty ||
        this.formGroup.controls['mobileNo'].touched)) {
      if (this.formGroup.controls['mobileNo'].errors.pattern) {
        this.invalidMobileAsPerApiMsg = '';
      }
    }
    if (this.formGroup.controls['email'].invalid &&
      (this.formGroup.controls['email'].dirty ||
        this.formGroup.controls['email'].touched)) {
      if (this.formGroup.controls['email'].errors.email) {
        this.invalidEmailAsPerApiMsg = '';
      }
    }
  }
  onCancelSubscriber() {
    this.router.navigate(['/login'])
  }
  @HostListener('window:keydown', ['$event'])
  SpaceNotAllowed(ctrlValue: any) {
    if (ctrlValue.target.type == 'email' && ctrlValue.which === 32) {
      ctrlValue.preventDefault();
    }
    if (ctrlValue.target.selectionStart === 0 && ctrlValue.code === "Space") {
      ctrlValue.preventDefault();
    }
  }
  spaceNotAllow(event) {
    if (event.which == 32) {
      // alertify.error("Space Not Allowed");
      return true;
    }
  }
  onChangeArea(event) {
    this.typeOfPropertyList = [];
    this.buildingList = [];
    this.formGroup.get('typeOfProperty').reset();
    this.formGroup.get('typeOfProperty').updateValueAndValidity();
    this.formGroup.get('building').reset();
    this.formGroup.get('building').updateValueAndValidity();
    if (event && event.id) {
      this.getPropertyType();
    }
  }
  onChangePropertyTypeBuilding(event) {
    this.formGroup.get('building').reset();
    this.formGroup.get('building').updateValueAndValidity();
    let area = this.formGroup.get('area').value;
    this.buildingList = []
    if (event && event.value) {
      this.entityService.getDetailsByProperty(area, event.value).subscribe(res => {
        this.buildingList = res.dataList
      })
    }
  }
  onChangePropertyType(event) {
    this.formGroup.get('propertyType').setValue(event.text);
    this.formGroup.updateValueAndValidity();
  }
  getPropertyType() {

    this.entityService.getCommonListtypeOfProperty('typeOfProperty').subscribe(res => {
      this.typeOfPropertyList = res.dataList
    })
  }
  checkUniqueCustomerByEmail(el) {
    if (this.formGroup.get('email').value !== null &&
      this.formGroup.get('email').value !== undefined) {
      ////console.log("Email Uni"+this.formGroup.get('email').value)
      let value = el.target.value;
      if (value && value.length) {
        this.invalidEmailAsPerApi = false;
        this.entityService.checkUniqueCustomerByEmail(value, null).subscribe(result => {
          if (result.responseCode !== 200) {
            this.invalidEmailAsPerApi = true;
            this.invalidEmailAsPerApiMsg = result.responseMessage;
            this.onTouchedandChange();
          } else {
            this.invalidEmailAsPerApi = false;
            this.invalidEmailAsPerApiMsg = '';
            this.onTouchedandChange();
          }
        });
      } else {
        this.invalidEmailAsPerApi = false;
        this.invalidEmailAsPerApiMsg = '';
      }
    }
    else {
      // 3 LINE New code
      this.invalidEmailAsPerApi = false;
      this.invalidEmailAsPerApiMsg = '';
      this.onTouchedandChange();
    }
  }
  getAddressMapping() {
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray = temp.filter(res => res.name.toLowerCase() != 'pincode' && res.name.toLowerCase() != 'area' && res.name.toLowerCase() != 'building')
    this.addressMapArray2 = temp.filter(res => (res.name.toLowerCase() == 'area'))
    this.addressMapArray3 = temp.filter(res => (res.name.toLowerCase() == 'building'))
    let tempn = temp.filter(res => (res.name.toLowerCase() == 'pincode'))
    this.parentpin = tempn[0].levelNo
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'district')) {
      this.formGroup.get('District').setValidators([Validators.required]);
      this.formGroup.get('District').updateValueAndValidity();
    }
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'taluka')) {
      this.formGroup.get('Taluka').setValidators([Validators.required]);
      this.formGroup.get('Taluka').updateValueAndValidity();
    }
    if (this.addressMapArray.find(res => res.name.toLowerCase() == 'constituency')) {
      this.formGroup.get('Constituency').setValidators([Validators.required]);
      this.formGroup.get('Constituency').updateValueAndValidity();
    }
  }
  getAddressMapping2() {
    let temp = this.tokenservice.getAddressLevel()
    this.addressMapArray4 = temp.filter(res => res.name.toLowerCase() != 'pincode' && res.name.toLowerCase() != 'area' && res.name.toLowerCase() != 'building')
    this.addressMapArray5 = temp.filter(res => (res.name.toLowerCase() == 'area'))
    this.addressMapArray6 = temp.filter(res => (res.name.toLowerCase() == 'building'))
    let tempn = temp.filter(res => (res.name.toLowerCase() == 'pincode'))
    this.parentpin = tempn[0].levelNo
    if (this.addressMapArray4.find(res => res.name.toLowerCase() == 'district')) {
      // this.paymentAddressFormGroup.get('District1').setValidators([Validators.required]);
      // this.paymentAddressFormGroup.get('District1').updateValueAndValidity();
      this.paymentAddressFormGroup.get('District2').setValidators([Validators.required]);
      this.paymentAddressFormGroup.get('District2').updateValueAndValidity();
    }
    if (this.addressMapArray4.find(res => res.name.toLowerCase() == 'taluka')) {
      // this.paymentAddressFormGroup.get('Taluka1').setValidators([Validators.required]);
      // this.paymentAddressFormGroup.get('Taluka1').updateValueAndValidity();
      this.paymentAddressFormGroup.get('Taluka2').setValidators([Validators.required]);
      this.paymentAddressFormGroup.get('Taluka2').updateValueAndValidity();
    }
    if (this.addressMapArray4.find(res => res.name.toLowerCase() == 'constituency')) {
      // this.paymentAddressFormGroup.get('Constituency1').setValidators([Validators.required]);
      // this.paymentAddressFormGroup.get('Constituency1').updateValueAndValidity();
      this.paymentAddressFormGroup.get('Constituency2').setValidators([Validators.required]);
      this.paymentAddressFormGroup.get('Constituency2').updateValueAndValidity();
    }
    console.log('-->', this.addressMapArray4)
  }
  onPincodeChange(elItem?) {
    if (!elItem.event) {
      this.formGroup.get('area').reset();
      this.formGroup.get('typeOfProperty').reset();
      this.formGroup.get('building').reset();
    }//this.buildingList = [];
    let el;
    let call_from_autocomplete;

    if (_.isArray(elItem.event) == true) {
      console.log(elItem.event);
      elItem.event.forEach((item) => {
        if (item && item.addressDetails && item.addressDetails.pincodeId && this.paymentPincodeId === item.addressDetails.pincodeId) {
          el = item;
          return
        }
      });
      ;
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    this.presentpincode=el.name
    if (el) {
      this.formGroup.controls.pincodeId.setValue(el.addressDetails.pincodeId);
      // this.formGroup.get('stateId').setValue(el.addressDetails.state)
      this.formGroup.patchValue({ Country: el.addressDetails.country });
      this.formGroup.patchValue({ State: el.addressDetails.state });
      this.formGroup.patchValue({ City: el.addressDetails.city });
      this.formGroup.patchValue({ District: el.addressDetails.district });
      this.formGroup.patchValue({ Taluka: el.addressDetails.taluka });
      this.formGroup.patchValue({ Constituency: el.addressDetails.constituency });
      this.formGroup.patchValue({ countryId: el.addressDetails.countryId });
      this.formGroup.patchValue({ stateId: el.addressDetails.stateId });
      this.stateId.emit(el.addressDetails.stateId);
      this.formGroup.patchValue({ cityId: el.addressDetails.cityId });
      this.formGroup.patchValue({ districtId: el.addressDetails.districtId });
      this.formGroup.patchValue({ talukaId: el.addressDetails.talukaId });
      this.formGroup.patchValue({ constituencyId: el.addressDetails.constituencyId });
      for (const prop in this.formGroup.controls) {
        this.formGroup.value[prop] = this.formGroup.controls[prop].value;
      }
      this.parentId = el.parentId;
      this.entityService.getDetailsByPincode(el.parentId).subscribe(res => {
        this.areaAllList = res.dataList;
      })
    }
    else if (el == null) {
      this.formGroup.get('pincodeId').reset()
      this.formGroup.get('Constituency').reset()
      this.formGroup.get('Taluka').reset()
      this.formGroup.get('District').reset()
      this.formGroup.get('City').reset()
      this.formGroup.get('State').reset()
      this.formGroup.get('Country').reset()
      this.formGroup.get('area').reset()
      this.areaAllList = [];
    }
  }
  onPincode2Change(elItem, id?) {
    if (!elItem.event) {
      this.paymentAddressFormGroup.get('area2').reset();
      this.paymentAddressFormGroup.get('typeOfProperty2').reset();
      this.paymentAddressFormGroup.get('buildingId2').reset();
    }
    let el;
    let call_from_autocomplete;
    if (_.isArray(elItem.event) == true) {
      console.log(elItem.event);
      elItem.event.forEach((item) => {
        if (item && item.addressDetails && item.addressDetails.pincodeId && this.paymentPincodeId === item.addressDetails.pincodeId) {
          el = item;
          return
        }
      });
      ;
    } else {
      el = elItem.event;
      call_from_autocomplete = elItem.call_from_autocomplete;
    }
    this.paymentpincode=el.name
    if (el && el.name) {
      this.paymentAddressFormGroup.controls.pincode2.setValue(el.addressDetails.pincodeId);
      this.paymentAddressFormGroup.patchValue({ Country2: el.addressDetails.country });
      this.paymentAddressFormGroup.patchValue({ State2: el.addressDetails.state });
      this.paymentAddressFormGroup.patchValue({ City2: el.addressDetails.city });
      this.paymentAddressFormGroup.patchValue({ District2: el.addressDetails.district });
      this.paymentAddressFormGroup.patchValue({ Taluka2: el.addressDetails.taluka });
      this.paymentAddressFormGroup.patchValue({ Constituency2: el.addressDetails.constituency });
      this.paymentAddressFormGroup.patchValue({ countryId2: el.addressDetails.countryId });
      this.paymentAddressFormGroup.patchValue({ stateId2: el.addressDetails.stateId });
      this.paymentAddressFormGroup.patchValue({ cityId2: el.addressDetails.cityId });
      this.paymentAddressFormGroup.patchValue({ districtId2: el.addressDetails.districtId });
      this.paymentAddressFormGroup.patchValue({ talukaId2: el.addressDetails.talukaId });
      this.paymentAddressFormGroup.patchValue({ constituencyId2: el.addressDetails.constituencyId });
      this.entityService.getDetailsByPincode(el.parentId).subscribe(result => {
        this.area2AllList = result.dataList;
        let data = result.data;
      });
    }
    else if (el == null) {
      this.paymentAddressFormGroup.get('pincodeId2').reset()
      this.paymentAddressFormGroup.get('Constituency2').reset()
      this.paymentAddressFormGroup.get('Taluka2').reset()
      this.paymentAddressFormGroup.get('District2').reset()
      this.paymentAddressFormGroup.get('City2').reset()
      this.paymentAddressFormGroup.get('State2').reset()
      this.paymentAddressFormGroup.get('Country2').reset()
      this.paymentAddressFormGroup.get('area2').reset()
    }
  }
  // chkSameAsPresentAddressForPaymentAddressDetails(el) {
  //   if (el.checked === true) {
  //     this.default_pincode2_value = this.pincode
  //     let formData = {
  //       Country2: this.formGroup.get('Country').value,
  //       State2: this.formGroup.get('State').value,
  //       City2: this.formGroup.get('City').value,
  //       District2: this.formGroup.get('District').value,
  //       Constituency2: this.formGroup.get('Constituency').value,
  //       Taluka2: this.formGroup.get('Taluka').value,
  //       typeOfProperty2: this.formGroup.get('typeOfProperty').value,
  //       buildingId2: this.formGroup.get('building').value,
  //       address2: this.formGroup.get('address').value,
  //       landmark2: this.formGroup.get('landmark').value,
  //       area2: this.formGroup.get('areaId').value,
  //       pincode2: this.formGroup.get('pincodeId').value,
  //       block2: this.formGroup.get('block').value,
  //     }
  //     this.paymentAddressFormGroup.patchValue(formData);
  //   }
  //   else {
  //     this.default_pincode2_value = '';
  //     let formData = {
  //       Country2: null,
  //       State2: null,
  //       City2: null,
  //       District2: null,
  //       Constituency2: null,
  //       Taluka2: null,
  //       typeOfProperty2: null,
  //       building2: null,
  //       address2: null,
  //       landmark2: null,
  //       area2: null,
  //       pincode2: null,
  //       block2: null
  //     };
  //     this.paymentAddressFormGroup.patchValue(formData);
  //   }
  // }
  onChangePropertyTypeBuilding2(event) {
    this.paymentAddressFormGroup.get('buildingId2').reset();
    this.paymentAddressFormGroup.get('buildingId2').updateValueAndValidity();
    let area = this.paymentAddressFormGroup.get('area2').value;
    this.buildingList2 = []
    if (event && event.value) {
      this.entityService.getDetailsByProperty(area, event.value).subscribe(res => {
        this.buildingList2 = res.dataList
      })
    }
  }
  onChangeArea2(event) {
    this.typeOfPropertyList2 = [];
    this.buildingList2 = [];
    this.paymentAddressFormGroup.get('typeOfProperty2').reset();
    this.paymentAddressFormGroup.get('typeOfProperty2').updateValueAndValidity();
    this.paymentAddressFormGroup.get('buildingId2').reset();
    this.paymentAddressFormGroup.get('buildingId2').updateValueAndValidity();
    if (event && event.id) {
      this.getPropertyType2();
    }
  }
  getPropertyType2() {
    this.entityService.getCommonListtypeOfProperty('typeOfProperty').subscribe(result => {
      this.typeOfPropertyList2 = result.dataList
    })
  }
  onTelephoneLineCheck(event) {
    if (event.checked === true) {
      this.data.telephoneLine = true
    }
    else {
      this.data.telephoneLine = false
    }
  }
  onStatickIpCheck(event) {
    if (event.checked === true) {
      this.data.staticIp = true
    }
    else {
      this.data.staticIp = false
    }
  }
  chkSameAsPresentAddressForPaymentAddressDetails(el) {
    if (el.checked === true) {
      this.hidePaymentAddressSection = true;
      this.data.sameAsPresent = true
      this.paymentAddressFormGroup.get('address2').clearValidators();
      this.paymentAddressFormGroup.get('address2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('pincode2').clearValidators();
      this.paymentAddressFormGroup.get('pincode2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('Country2').clearValidators();
      this.paymentAddressFormGroup.get('Country2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('State2').clearValidators();
      this.paymentAddressFormGroup.get('State2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('District2').clearValidators();
      this.paymentAddressFormGroup.get('District2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('talukaId2').clearValidators();
      this.paymentAddressFormGroup.get('talukaId2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('constituencyId2').clearValidators();
      this.paymentAddressFormGroup.get('constituencyId2').updateValueAndValidity();
      this.paymentAddressFormGroup.get('cityId2').clearValidators();
      this.paymentAddressFormGroup.get('cityId2').updateValueAndValidity();
    }
    else {
      this.data.sameAsPresent = false
      if (el.checked === false) {
        this.hidePaymentAddressSection = false;
        if (this.data && this.data.addressList) {
          for (let i = 0; i < this.data.addressList.length; i++) {
            if (this.data.addressList[i].addressType === 'Payment') {
              this.data.addressList.splice(i, 1);
            }
          }
        }
        this.paymentAddressFormGroup.get('address2').setValidators([Validators.required,malicliousInputValidator()]);
        this.paymentAddressFormGroup.get('address2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('pincode2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('pincode2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('Country2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('Country2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('State2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('State2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('District2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('District2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('talukaId2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('talukaId2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('constituencyId2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('constituencyId2').updateValueAndValidity();
        this.paymentAddressFormGroup.get('cityId2').setValidators([Validators.required]);
        this.paymentAddressFormGroup.get('cityId2').updateValueAndValidity();
      }
    }
  }
  onChangeLeadSource(event){
    if(event && event.value == 'referredByFriendOrRelative'){
      this.formGroup.get('relativeUsername').setValidators([Validators.required]);
      this.formGroup.get('relativeUsername').updateValueAndValidity();
      this.formGroup.get('relativeSource').setValidators([Validators.required]);
      this.formGroup.get('relativeSource').updateValueAndValidity();
    }
    else{
      this.formGroup.get('relativeUsername').reset();
      this.formGroup.get('relativeUsername').clearValidators();
      this.formGroup.get('relativeUsername').setValidators(null);
      this.formGroup.get('relativeUsername').updateValueAndValidity();
      this.formGroup.get('relativeSource').clearValidators();
      this.formGroup.get('relativeSource').reset();
      this.formGroup.get('relativeSource').setValidators(null);
      this.formGroup.get('relativeSource').updateValueAndValidity();
    }
  }
  getLeadSourceList() {
    this.resetPasswordService.getLeadSource().subscribe((res) => {
      this.leadSourceList = res.dataList;
    });
  }
  getRelativerSourceList() {
    this.resetPasswordService.getRelativeSource().subscribe((res) => {
      this.relativeSourceList = res.dataList;
    });
  }
  public getSubscriberCustomer() {
    this.CustomerList = 'lead/operation/searchForAll?s=';
  }
  setSubscriber(eventcust){
    if(!eventcust.event){
      this.formGroup.get('relativeUsername').setValidators([Validators.required]);
      this.formGroup.get('relativeUsername').updateValueAndValidity()
      this.formGroup.get('relativeUsername').reset()
    }
    else{
      let event = eventcust.event;
      this.rcustname=event.username;
      this.formGroup.get('relativeUsername').patchValue(this.rcustname); 
    }
  }
  openMap() {
    this.showMap = true;
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This will add a smooth scrolling effect
    });
  }
  onBack(){
    this.latitude=null
    this.longitude=null
    if(this.formGroup.get('relativeUsername').value!==undefined){
      this.username=this.formGroup.get('relativeUsername').value
    }
    if(this.data.sameAsPresent){
      if(this.formGroup.get('pincodeId').value!==undefined){
        this.paymentpincode=this.presentpincode
        this.default_pincode_value=this.presentpincode
      }
    }
    if(!this.data.sameAsPresent){
      this.enablePayment=false
      if(this.formGroup.get('pincodeId').value!==undefined){
        this.default_pincode_value=this.presentpincode
      }
      if(this.paymentAddressFormGroup.get('pincode2').value!==undefined){
        this.default_pincode2_value=this.paymentpincode
      }
    }
   
    let latitude =this.data.latitude
    let longitude=this.data.longitude
    this.showMap=false
    if(!latitude){
      this.formGroup.get('longitude').reset()
    }
    if(!longitude){
      this.formGroup.get('latitude').reset()
    } 
   }
   onsave(){
    this.showMap=false
    this.data.longitude=this.longitude
    this.data.latitude=this.latitude
    if(this.formGroup.get('relativeUsername').value!==undefined){
      this.username=this.formGroup.get('relativeUsername').value
    }
    if(this.data.sameAsPresent){
      if(this.formGroup.get('pincodeId').value!==undefined){
        this.paymentpincode=this.presentpincode
        this.default_pincode_value=this.presentpincode
        this.default_pincode2_value=this.paymentpincode
      }
    }
    if(!this.data.sameAsPresent){
      this.enablePayment=false
      if(this.formGroup.get('pincodeId').value!==undefined){
        this.default_pincode_value=this.presentpincode
      }
      if(this.paymentAddressFormGroup.get('pincode2').value!==undefined){
        this.default_pincode2_value=this.paymentpincode
      }
    }
   }
   getLatitude(event){
    this.latitude=event
    }
    getLongitude(event){
    this.longitude=event
  }
}

export function latitudeValidatorlead(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const pattern = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,16}$/;
    if (value && !pattern.test(value)) {
      return { 'pattern': true };
    }

    return null;
  };
}

export function longitudeValidatorlead(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const pattern = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}$/;
    if (value && !pattern.test(value)) {
      return { 'pattern': true };
    }

    return null;
  };
}
